import { useEffect, useState } from 'react'
import shopify from '../../assets/shopifybag.png'
import profileicon from '../../assets/botprofileicon.png'
import mobileimage from '../../assets/botcustomization_mobileimage.png'
import { HexColorPicker } from 'react-colorful' // Using HexColorPicker for hex format directly
// import "react-color-palette/css";
import { apiFetch } from '../../services/apihelper'
import { toast, ToastContainer } from 'react-toastify'
import defaultlaunchericon from '../../assets/botcustomization/chaticon.png'
import defaultboticon from '../../assets/botcustomization/magbotlogo.png'
import { getStorebyID } from '../../services/data'

const BotCustomization = () => {
  const [activeTab, setActiveTab] = useState('General')
  const [color, setColor] = useState('#7c50dd')
  const [valueRight, setValueRight] = useState(20) // Position from Right value
  const [valueLeft, setValueLeft] = useState(20) // Position from Left value
  const [valueBottom, setValueBottom] = useState(20) // Position from Bottom value
  const [positionSide, setPositionSide] = useState('right') // Toggle between left and right
  const [launcherImagePreview, setLauncherImagePreview] = useState(null)
  const [ChecklauncherImagePreview, SetChecklauncherImagePreview] =
    useState(true)
  const [botImagePreview, setBotImagePreview] = useState(null)
  const [CheckbotImagePreview, SetCheckbotImagePreview] = useState(true)
  const [botName, setBotName] = useState('Hello there! 👋') // State for Bot name
  const [welcomeMessage, setWelcomeMessage] = useState(
    'Welcome to MagBot Pro<br>Im here to assist you with your questions.'
  ) // State for Welcome message
  const [storeId, setstoreId] = useState('')
  const [storeName, setstoreName] = useState('')
  const [checkType,setcheckType] = useState('')
  const [showHint, setshowHint] = useState(true)

  useEffect(() => {
    // Set admin to true when the component is mounted
    if (
      window.chatbotConfig &&
      window.location.pathname.includes('bot-customization')
    ) {
      setTimeout(() => {
        if (
          window.chatbotConfig &&
          typeof window.chatbotConfig.reloadInitConfig === 'function'
        ) {
          window.chatbotConfig.open = true
        }
      }, 2000)

      // // Set admin back to false after 10 seconds
      // const timeoutId = setTimeout(() => {
      //     if (window.chatbotConfig) {
      //         window.chatbotConfig.open = false;
      //     }
      // }, 10000); // 10000ms = 10 seconds

      // // Clean up function to clear the timeout when the component is unmounted
      // return () => {
      //     clearTimeout(timeoutId);
      //     if (window.chatbotConfig && !window.location.pathname.includes('bot-customization')) {
      //         window.chatbotConfig.open = false;
      //     }
      // };
    }
  }) // Empty dependency array ensures this runs only once on mount and unmount

  useEffect(() => {
    if (sessionStorage.getItem('store_id')) {
      setstoreId(sessionStorage.getItem('store_id'))
    }
  }, [color])

  useEffect(() => {
    const GetStoreName = async () => {
      if (sessionStorage.getItem('store_id')) {
        const getStorename = await getStorebyID(
          sessionStorage.getItem('store_id')
        )
        setstoreName(getStorename.data.name.replace('.myshopify.com', ''));
        setcheckType(getStorename.data.type)
      }
    }

    GetStoreName()
  }, [storeName,checkType])

  console.log(storeName)

  // Get bot configuration when store_id is available
  useEffect(() => {
    if (storeId) {
      const fetchBotConfig = async () => {
        try {
          const response = await apiFetch(`bots?store_id=${storeId}`)
          if (response.success && response.data) {
            if (response.data.length === 0) {
              // window.chatbotConfig.botLauncherImageUrl = defaultlaunchericon
              // window.chatbotConfig.botImageUrl = defaultboticon
              setBotName(window.chatbotConfig.title)
              setWelcomeMessage(window.chatbotConfig.subtitle)
              setValueBottom(window.chatbotConfig.bottom.replace('px', ''))
              setValueRight(window.chatbotConfig.right.replace('px', ''))
              setValueLeft(window.chatbotConfig.left.replace('px', ''))
              setColor(window.chatbotConfig.colorScheme)
              setLauncherImagePreview(
                window.chatbotConfig.botLauncherImageUrl || null
              )
              setBotImagePreview(window.chatbotConfig.botImageUrl || null)
            } else {
              const botConfig = JSON.parse(response.data[0].config)
              console.log(botConfig)
              // window.chatbotConfig = { ...botConfig };
              setBotName(botConfig.title || '')
              setWelcomeMessage(botConfig.subtitle || '')
              setValueRight(botConfig.right.replace('px', ''))
              setValueLeft(botConfig.left.replace('px', ''))
              setValueBottom(botConfig.bottom.replace('px', ''))
              setLauncherImagePreview(botConfig.botLauncherImageUrl || null)
              setBotImagePreview(botConfig.botImageUrl || null)
              setColor(botConfig.colorScheme)
            }
          }
        } catch (error) {
          console.error('Error fetching bot config:', error)
        }
      }

      fetchBotConfig()
    }
  }, [storeId])

  const handleRightChange = e => {
    setValueRight(e.target.value)
  }

  const handleLeftChange = e => {
    setValueLeft(e.target.value)
  }

  const handleBottomChange = e => {
    setValueBottom(e.target.value) // Handle bottom position change
  }

  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  const handlePositionToggle = pos => {
    // setPositionSide(pos === 'right' ? 'left' : 'right');
    if (pos === 'left') {
      window.chatbotConfig.left = valueLeft
      setPositionSide('left')
    } else {
      window.chatbotConfig.right = valueRight
      setPositionSide('right')
    }
  }

  useEffect(() => {
    if (
      window.chatbotConfig &&
      typeof window.chatbotConfig.reloadInitConfig === 'function'
    ) {
      window.chatbotConfig.title = botName
      window.chatbotConfig.subtitle = welcomeMessage
      window.chatbotConfig.bottom = valueBottom + 'px'
      window.chatbotConfig.right =
        positionSide === 'right' ? valueRight + 'px' : '' // Handling position right value
      window.chatbotConfig.left =
        positionSide === 'left' ? valueLeft + 'px' : '' // Handling position right value
      window.chatbotConfig.colorScheme = `${color}`
    }
  }, [
    botName,
    welcomeMessage,
    valueRight,
    valueLeft,
    valueBottom,
    positionSide,
    color
  ])

  const handleLauncherImageChange = async event => {
    const file = event.target.files[0]
    if (file) {
      // Preview the image
      const reader = new FileReader()
      reader.onloadend = async () => {
        console.log(reader.result)
        setLauncherImagePreview(reader.result)

        // Prepare the form data to send as binary
        const formData = new FormData()
        formData.append('image', file) // Append the file with the correct field name ('image')

        try {
          // Send the file to the server using fetch with the FormData (binary data will be automatically handled)
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/upload/image`,
            {
              method: 'POST',
              body: formData, // Body is the form data which includes the binary file
              headers: {
                ...(sessionStorage.getItem('token')
                  ? {
                      Authorization: `Bearer ${sessionStorage.getItem('token')}`
                    }
                  : {}) // Include the token in headers if it exists
              }
            }
          )

          // Check if the upload was successful
          if (response.ok) {
            const data = await response.json()
            const uploadedImageUrl = `${process.env.REACT_APP_AWS_URL}/${data.data.filename}` // Assumin    g the response contains the URL
            SetChecklauncherImagePreview(false)
            // Set the URL in your configuration or state
            window.chatbotConfig.botLauncherImageUrl = uploadedImageUrl
          } else {
            console.error('Failed to upload image')
          }
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }
      reader.readAsDataURL(file) // Read the file for preview
    }
  }

  const handleBotImageChange = async event => {
    const file = event.target.files[0]
    if (file) {
      // Preview the image
      const reader = new FileReader()
      reader.onloadend = async () => {
        setBotImagePreview(reader.result)

        // Prepare the form data to send as binary
        const formData = new FormData()
        formData.append('image', file) // Append the file with the correct field name ('image')

        try {
          // Send the file to the server using fetch with the FormData (binary data will be automatically handled)
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/upload/image`,
            {
              method: 'POST',
              body: formData, // Body is the form data which includes the binary file
              headers: {
                ...(sessionStorage.getItem('token')
                  ? {
                      Authorization: `Bearer ${sessionStorage.getItem('token')}`
                    }
                  : {}) // Include the token in headers if it exists
              }
            }
          )

          // Check if the upload was successful
          if (response.ok) {
            const data = await response.json()
            const uploadedImageUrl = `${process.env.REACT_APP_AWS_URL}/${data.data.filename}` // Assumin    g the response contains the URL

            SetCheckbotImagePreview(false)
            // Set the URL in your configuration or state
            window.chatbotConfig.botImageUrl = uploadedImageUrl
          } else {
            console.error('Failed to upload image')
          }
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }
      reader.readAsDataURL(file) // Read the file for preview
    }
  }

  const handleSaveConfig = async () => {
    if (sessionStorage.getItem('store_id')) {
      setstoreId(sessionStorage.getItem('store_id'))
    } else {
      return
    }
    const configData = {
      store_id: storeId,
      config: {
        title: window.chatbotConfig.title,
        subtitle: window.chatbotConfig.subtitle,
        colorScheme: window.chatbotConfig.colorScheme,
        botImageUrl: window.chatbotConfig.botImageUrl,
        botLauncherImageUrl: window.chatbotConfig.botLauncherImageUrl,
        position: 'fixed',
        bottom: window.chatbotConfig.bottom,
        right: window.chatbotConfig.right,
        left: window.chatbotConfig.left
      },
      meta: {}
    }

    try {
      const response = await apiFetch('bots', {
        method: 'POST',
        body: JSON.stringify(configData)
      })

      if (response.success) {
        // console.log("Configuration saved successfully!");
        toast.success('Configuration saved successfully!', {
          autoClose: 3000,
          position: 'bottom-center'
        })
      } else {
        // console.error("Failed to save configuration.");
        toast.error('Failed to save configuration.', {
          autoClose: 3000,
          position: 'bottom-center'
        })
      }
    } catch (error) {
      console.error('Error saving configuration:', error)
    }
  }

  // console.log(color);

  return (
    <div className='pt-9 pl-7 block'>
      <div className='block'>
        <div className='flex space-x-2 items-center'>
          {/* <img src={shopify} alt="Shopify Bag" className='w-[32px] h-[32px]' /> */}
          <h1 className='text-[#252530] font-extrabold text-[23px]'>
            Customize Your Bot
          </h1>
        </div>
        <p className='font-medium mt-1 text-[#252530] text-[12px]'>
          Personalize Your Bot for Enhanced User Engagement
        </p>
      </div>
      <div className='mt-10 pb-4 w-[60%] md:w-[30%] inline-block'>
        <ul className='flex space-x-[12%] border-b-[1px] border-[#786E6E] border-opacity-20'>
          {['General', 'Color Theme'].map(tab => (
            <li
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`cursor-pointer pb-2 ${
                activeTab === tab
                  ? 'border-b-[3px] border-[#7C50DD] pb-4 font-[700] text-[#252530] text-[15px]'
                  : 'text-gray-500 font-medium text-[15px]'
              }`}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Tab Content */}
      <div className='mt-6 flex gap-[15%]'>
        {activeTab === 'General' && (
          <div className='block mt-4'>
            {ChecklauncherImagePreview === true ||
            CheckbotImagePreview === true ? (
              <div className='mb-5'>
                <small className='font-normal'>*change default image</small>
              </div>
            ) : (
              <></>
            )}
            <div className='flex  justify-start items-center space-x-8'>
              {/* botLauncherImageUrl */}
              <div className='flex flex-col items-center space-y-2 '>
                <img
                  src={launcherImagePreview || null}
                  alt='Launcher'
                  className='rounded-full  w-[70px] h-[70px]'
                />

                <input
                  type='file'
                  id='uploadBotLauncherImage'
                  style={{ display: 'none' }}
                  onChange={handleLauncherImageChange}
                />
                <label
                  htmlFor='uploadBotLauncherImage'
                  className='bg-[#7C50DD] rounded-[55px] font-[500] text-[10px] w-[128px] h-[36px] flex items-center justify-center text-[#FFF] cursor-pointer shadow-md hover:shadow-lg'
                >
                  Upload Launcher Image
                </label>
              </div>

              {/* botImageUrl */}
              <div className='flex flex-col items-center space-y-2'>
                <img
                  src={botImagePreview || null}
                  alt='Bot'
                  className=' w-[70px] h-[70px] object-contain'
                />

                <input
                  type='file'
                  id='uploadBotImage'
                  style={{ display: 'none' }}
                  onChange={handleBotImageChange}
                />
                <label
                  htmlFor='uploadBotImage'
                  className='bg-[#7C50DD] rounded-[55px] font-[500] text-[10px] w-[128px] h-[36px] flex items-center justify-center text-[#FFF] cursor-pointer shadow-lg hover:shadow-xl'
                >
                  Upload Bot Image
                </label>
              </div>
            </div>

            <div className='mt-10 block'>
              <div className='block'>
                <p className='font-[400] text-[14px] text-[#2D3748]'>Title</p>
                <input
                  type='text'
                  className='w-[258px] mt-1 border-[#D1D1D1] border-[1px] h-[38px] rounded-[14px] bg-[#f0eeee] focus:outline-none pl-4 text-[12px] placeholder:text-[12px]'
                  placeholder='Chatedge Pro'
                  onChange={e => setBotName(e.target.value)}
                  value={botName}
                />
              </div>
              <div className='block mt-5'>
                <p className='font-[400] text-[14px] text-[#2D3748]'>
                  Welcome message
                </p>
                <textarea
                  cols='30'
                  rows='10'
                  className='w-[258px] mt-1 border-[#D1D1D1] border-[1px] h-[130px] rounded-[14px] bg-[#f0eeee] focus:outline-none pl-4 text-[12px] pt-3'
                  onChange={e => setWelcomeMessage(e.target.value)}
                  value={welcomeMessage}
                ></textarea>
              </div>

              {/* Position from Right or Left */}
              <div className='block mt-5'>
                <div className='flex items-center space-x-3 mb-3'>
                  <p className='font-[400] text-[14px] text-[#2D3748]'>
                    Position
                  </p>

                  {/* Radio buttons for position */}
                  <div className='flex items-center space-x-3'>
                    <label className='inline-flex items-center'>
                      <input
                        type='radio'
                        name='positionSide'
                        value='left'
                        checked={positionSide === 'left'}
                        onChange={() => handlePositionToggle('left')}
                        className='form-radio text-blue-600'
                      />
                      <span className='ml-2 text-[14px] text-[#2D3748]'>
                        From Left
                      </span>
                    </label>

                    <label className='inline-flex items-center'>
                      <input
                        type='radio'
                        name='positionSide'
                        value='right'
                        checked={positionSide === 'right'}
                        onChange={() => handlePositionToggle('right')}
                        className='form-radio text-blue-600'
                      />
                      <span className='ml-2 text-[14px] text-[#2D3748]'>
                        From Right
                      </span>
                    </label>
                  </div>
                </div>

                <div className='flex items-center space-x-2 mt-1'>
                  {/* Range Slider for Left/Right */}
                  <input
                    type='range'
                    className='h-2 bg-gray-300 rounded-lg appearance-none focus:outline-none '
                    min='0'
                    max='100'
                    value={
                      positionSide === 'right'
                        ? valueRight
                        : valueLeft === 'auto'
                        ? 0
                        : valueLeft
                    } // Controlled value based on side
                    onChange={
                      positionSide === 'right'
                        ? handleRightChange
                        : handleLeftChange
                    } // Update value on change
                  />
                  <div className='w-[40px] h-[24px] pt-5 pb-5 pr-5 pl-5 flex items-center justify-center bg-gray-200 rounded-lg text-xs text-[#333]'>
                    {positionSide === 'right'
                      ? valueRight + 'px'
                      : valueLeft === 'auto'
                      ? '0px'
                      : valueLeft + 'px'}
                  </div>
                </div>
              </div>

              {/* Position From Bottom */}
              <div className='block mt-5'>
                <div className='flex items-center space-x-3'>
                  <p className='font-[400] text-[14px] text-[#2D3748]'>
                    Position From Bottom
                  </p>
                </div>

                <div className='flex items-center space-x-2'>
                  {/* Range Slider for Position from Bottom */}
                  <input
                    type='range'
                    className='h-2 bg-gray-300 rounded-lg appearance-none focus:outline-none '
                    min='0'
                    max='100'
                    value={valueBottom} // Controlled value for bottom positioning
                    onChange={handleBottomChange} // Handle change for bottom position
                  />
                  <div className='w-[40px] h-[24px] pt-5 pb-5 pr-5 pl-5 flex items-center justify-center bg-gray-200 rounded-lg text-xs text-[#333]'>
                    {valueBottom}px
                  </div>
                </div>
              </div>

              <div className='mt-5 mb-3 pb-5'>
                <button
                  onClick={handleSaveConfig}
                  className='bg-[#232328] w-[85px] h-[39px] rounded-[59px] text-white text-[14px]'
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'Color Theme' && (
          <div className='flex flex-col w-full space-y-3 mb-5'>
            <p className='text-[#2D3748] text-[14px] font-[400]'>
              Select Theme Color
            </p>
            <div style={{ width: '300px', height: 'auto' }}>
              <HexColorPicker color={color} onChange={setColor} />
            </div>

            <button
              onClick={handleSaveConfig}
              className='bg-[#232328] mb-9 w-[85px] h-[39px] rounded-[59px] text-white text-[14px] self-start'
            >
              Save
            </button>
          </div>
        )}
        { checkType === "shopify" &&
          <div className='w-full  md:w-1/2'>
            <div className='p-6 bg-gray-100 rounded-lg shadow-md'>
              <h3 className='text-lg font-semibold'>Instruction:</h3>
              <ul className='mt-4 ml-4 space-y-2 text-sm list-disc'>
                <li>
                  Go to a{' '}
                  <a
                    href={`https://admin.shopify.com/store/${storeName}/themes/current/editor?context=apps`}
                    target='_blank'
                    className='text-[#005cc8]'
                  >
                    {' '}
                    current theme{' '}
                  </a>
                </li>
                <li>App Embeds</li>
                <li>
                  Select App: <strong>Magbot - Chatbot</strong>
                </li>
                <li>
                  Copy/Paste Store ID: <strong>{storeId}</strong>
                </li>
              </ul>
            </div>
          </div>
        }
        {/* <img src={mobileimage} alt="" className='mt-[-8%] max-h-[450px] self-start' /> */}
      </div>
      <ToastContainer />
    </div>
  )
}

export default BotCustomization
