import React, { useState, useEffect } from 'react';
import { getUserbyID } from '../services/data';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const ShopifyCallback = () => {
  const navigate = useNavigate();
  const [toastShown, setToastShown] = useState(false); // Flag to track if toast has been shown

  const showToast = () => {
    if (!toastShown) {
      toast.success('Redirected to Owner account', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
        onClose: () => handleRedirect(),
      });
      setToastShown(true);
    }
  };

  const handleRedirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const storeId = urlParams.get('store_id');
    const onboarding = urlParams.get('onboarding');

    if (onboarding === 'true') {

      navigate('/home', {
        state: {
          fromSource: true,
          storeId: storeId,
          from: '/shopify/callback',
        },
      });
    } else {
      sessionStorage.removeItem('onboarding');
      sessionStorage.setItem('store_id', storeId);
      navigate('/training');
    }
  };

  const handlePageLoad = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const storeId = urlParams.get('store_id');
    const onboarding = urlParams.get('onboarding');
    const token = urlParams.get('token');
    const user_id = urlParams.get('user_id');

    if (storeId && token && user_id) {
      sessionStorage.setItem('onboarding', onboarding);
        
      const getuser = async () => {
        const existsUser = JSON.parse(sessionStorage.getItem('user'));
        if (existsUser?.id !== user_id) {
          sessionStorage.setItem('token', token);
          const res = await getUserbyID(user_id);
          sessionStorage.setItem("owner_account",false)
          sessionStorage.setItem('user', JSON.stringify(res.data[0]));

        } else {
          sessionStorage.setItem('token', token);
          handleRedirect();
        }
      };

      getuser();
    }
  };

  // Call handlePageLoad only once when the component mounts
  useEffect(() => {
    showToast();  // Show toast immediately after mount
    handlePageLoad(); // Then proceed with user data processing
  }, []); // Empty dependency array ensures this only runs once when the component mounts

  return(
    <div>
      <ToastContainer />
    </div>
  );
};

export default ShopifyCallback;
