import { useEffect, useState } from "react";
import { GetChatLog } from "../../services/data";
import DateTimeFormat from "../../util/DateTimeformat";

const Conversation = ({ chatlistId }) => {
    const chatLog = new GetChatLog();
    const [messages, setMessages] = useState([]);
    const [sortmessage, Setsortmessage] = useState([]);

    useEffect(() => {
        const GetConversationList = async (chatlist_id, storeID) => {
            const customerChatlist = await chatLog.Get_Chat_Log(storeID, chatlist_id);
            if (customerChatlist.data) {
                // Sort messages by updatedAt to ensure correct order
                let sortedMessages = customerChatlist.data.sort(
                    (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
                );

                // Adjust messages to reverse consecutive 'user' messages
                sortedMessages = adjustUserOrder(sortedMessages);

                // console.log(sortedMessages)

                setMessages(sortedMessages);
            }
        };

        const adjustUserOrder = (messages) => {
            return messages.reduce((acc, msg, index, arr) => {
                // Check if the current and previous messages are both 'user' and reverse order if true
                if (msg.role === 'user' && arr[index - 1]?.role === 'user') {
                    // Reverse the last two elements in the accumulator
                    const lastMessage = acc.pop();
                    acc.push(msg, lastMessage);
                } else {
                    acc.push(msg);
                }
                return acc;
            }, []);
        };

        // Get the store_id from sessionStorage (assuming it exists)
        const storeId = sessionStorage.getItem("store_id");

        if (storeId) {
            GetConversationList(chatlistId, storeId);
        }

        // Listen for changes to chatlist_customer_id and reload conversation list if it changes
        const handleGetChatid = (event) => {
            if (event.detail) {
                if (chatlistId && storeId) {
                    GetConversationList(chatlistId, storeId);
                }
            }
        };
        GetConversationList(chatlistId, storeId);

        window.addEventListener("loadchatlist", handleGetChatid)
        window.addEventListener("deletereload", handleGetChatid)

        window.addEventListener("chatlist_customer_id", handleGetChatid);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener("chatlist_customer_id", handleGetChatid);
            window.removeEventListener("loadchatlist", handleGetChatid)
            window.removeEventListener("deletereload", handleGetChatid)
        };
    }, [chatlistId]); // Dependency array includes chatlistId to reload when it changes

    useEffect(() => {
        if (messages.length > 0) {
            if (messages[0].role === "assistant") {
                setMessages([...messages]);
            }
        }
    }, [messages]);

    return (
        <div className="flex flex-col items-center pt-5 pb-5 pl-9 pr-8 w-full overflow-x-hidden max-h-[320px] scrollbar-custom1">
            <div className="w-full max-w-2xl space-y-8">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                        <div className="flex space-x-4 items-start max-w-md">
                            {/* Avatar and message content */}
                            {/* {msg.role === 'user' && (
                                <div className="w-9 h-8 rounded-full bg-[#bbbcbd] flex items-center justify-center">
                                </div>
                            )} */}

                            {/* Message Content */}
                            <div className="flex flex-col space-y-1 w-full  sm:w-[228px]">
                                {/* Timestamp and label */}
                                <div className={`flex items-center text-xs text-gray-500 ${msg.role === 'user' ? 'justify-end' : 'justify-between'}`}>
                                    <span>{DateTimeFormat(msg.updatedAt).time || 'Unknown Time'}</span>
                                    {/* {msg.role === 'assistant' && <span className="font-semibold mr-1">Shopify chatbot</span>} */}
                                </div>

                                {/* Message Bubble */}
                                <div
                                    className={`px-4 py-3 ${msg.role === 'user'
                                        ? 'bg-[#9b6dff] text-[#ffffff] rounded-tl-lg rounded-bl-lg rounded-br-lg'
                                        : 'bg-[#F2F2F2] text-[#768396] rounded-tr-lg rounded-br-lg rounded-bl-lg'
                                        } text-[12px] leading-relaxed`}
                                >
                                    {msg.content}
                                </div>
                            </div>

                            {/* Bot Avatar (placed on the right for assistant role) */}
                            {/* {msg.role === 'assistant' && (
                                <div className="w-9 h-8 rounded-full bg-[#bbbcbd] flex items-center justify-center">
                                </div>
                            )} */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Conversation;
