// services/apiHelper.js
import { toast } from 'react-toastify';
const BASE_URL = process.env.REACT_APP_API_URL+"/api/";  // Use your environment variable for the base URL

export const apiFetch = async (endpoint, options = {}) => {
    const token = sessionStorage.getItem('token'); // Get the token from sessionStorage
    const defaultHeaders = {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}), // Add Authorization header if token exists
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, {  // Prepend base URL to endpoint
        ...options,
        headers: {
            "ngrok-skip-browser-warning": "true",
            ...defaultHeaders,
            ...options.headers,  // Merge with any headers provided in the request
        },
    });

    if (!response.ok) {
        
        const errorData = await response.json();
        console.log(errorData)
        throw new Error(errorData.message || 'API call failed');
        
    }

    return response.json();  // Parse and return the response JSON
};
