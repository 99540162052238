import React, { useState, useEffect } from "react";
import chatbotlogo from "../../assets/chatbotlogo.png";
import { toast, ToastContainer } from "react-toastify"; // For toast notifications
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Font Awesome icons
import { useLocation } from "react-router-dom"; // For getting the token from the URL
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [token, setToken] = useState("");
    // eslint-disable-next-line
    const navigate = useNavigate(); // Initialize useNavigate

    const location = useLocation();

    // Extract the token from the URL when the component mounts
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            setToken(token);
        } else {
            toast.error("Invalid or missing token", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation: Check if both password fields are filled and match
        if (newPassword === "" || confirmPassword === "") {
            toast.error("Please fill in both password fields", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            // Submit the form if valid
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token,  // Pass the token from the URL
                        newPassword: newPassword,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to reset password");
                }

                toast.success("Password reset successful", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate('/'),
                });
               
                // Optionally redirect the user to login or another page
            } catch (error) {
                console.error(error);
                toast.error("Failed to reset password. Please try again later.", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    return (
        <div className="p-5 md:p-0">
            <div className="flex flex-col items-center justify-center min-h-screen relative">
                {/* Back to login button positioned at the top left */}
                <div className="absolute top-4 left-4 flex items-center">
                    <a href="/" className="flex">
                        <svg width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.8656 5.19692C19.3781 5.70949 19.3781 6.54051 18.8656 7.05308L11.9187 14L18.8656 20.9469C19.3781 21.4595 19.3781 22.2905 18.8656 22.8031C18.353 23.3156 17.522 23.3156 17.0094 22.8031L9.13442 14.9281C8.62186 14.4155 8.62186 13.5845 9.13442 13.0719L17.0094 5.19692C17.522 4.68436 18.353 4.68436 18.8656 5.19692Z" fill="#373737" />
                        </svg>
                        <p className="font-[500] text-[14px] ml-2 leading-5">Back to login</p>
                    </a>
                </div>

                <div className="flex justify-center items-start w-full max-w-md">
                    <div className="block w-full">
                        <div className="flex justify-center items-start mb-3">
                            <img src={chatbotlogo} alt="chatedge" className="w-auto h-[55px] mx-auto" />
                        </div>
                        <div className="flex justify-center items-center mb-9">
                            <div className="block text-center">
                                <p className="font-[400] text-[28px] leading-[38px]">Reset your password</p>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            {/* New Password Field */}
                            <div className="block mb-5 relative">
                                <p className="font-[400] text-[14px] leading-[14px] text-[#333333] mb-1">New Password</p>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="w-full h-[48px] p-2 pl-2 rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter new password"
                                />
                                {/* Eye Icon for Password Visibility Toggle */}
                                <span
                                    onClick={() => setShowPassword(!showPassword)}
                                    className='absolute right-3 top-[70%] text-[12px] transform -translate-y-1/2 text-[#d4d4d4] cursor-pointer focus:outline-none'>
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                </span>
                            </div>

                            {/* Confirm Password Field */}
                            <div className="block mb-4 relative">
                                <p className="font-[400] text-[14px] leading-[14px] text-[#333333] mb-1">Confirm Password</p>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className="w-full h-[48px] p-2 pl-2 rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirm new password"
                                />
                                {/* Eye Icon for Confirm Password Visibility Toggle */}
                                <span
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    className='absolute right-3 top-[70%] text-[12px] transform -translate-y-1/2 text-[#d4d4d4] cursor-pointer focus:outline-none'>
                                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                                </span>
                            </div>

                            <div className="flex justify-center items-start mt-5">
                                <button type="submit" className="bg-[#7C50DD] w-full h-[48px] rounded-[55px] text-[#FFFFFF] text-[14px] leading-[21px] font-[600]">
                                    Reset Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ResetPassword;
