// import React, { useState } from 'react';
import './style.css'
import { useLogin } from '../../hooks/uselogin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/chatbotlogo.png'
import bannerimage from '../../assets/bannerimage.png'
import { Link, useNavigate } from 'react-router-dom'
// import { loginUser } from '../../services/authentication/login-service';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
// import Bannerimg from "../../assets/Banner-Img.jpg"

const LoginPage = () => {
  const navigate = useNavigate()
  const {
    passwordVisible,
    email,
    password,
    setEmail,
    setPassword,
    togglePasswordVisibility,
    handleLogin
  } = useLogin()

  const onSubmit = e => {
    e.preventDefault()
    handleLogin(navigate)
  }

  return (
    <>
      <div className=' md:h-[100vh]  w-full  grid grid-rows-2 md:grid-cols-2'>
        {/* Left Section */}
        <div className='w-full h-full login-description centered flex flex-col justify-start items-center p-10  4xl:pl-[20%]  4xl:pr-[20%] 3xl:pl-[20%] 3xl:pr-[20%] md:min-h-screen md:overflow-y-hidden'>
          <h2 className='text-center font-[600] text-[26px] leading-[24px] pl-13 pr-13 w-[90%] md:w-[70%] pb-3 text-white'>
            Scale Your eCommerce Growth
          </h2>
          <p className='font-[400] text-[14px] 4xl:text-[19px] 3xl:text-[19px] mb-5 text-white'>
            Welcome to <a href='https://magbot.ai/'>
              <span className='text-[#FFF] underline'>MagBot</span>
            </a> – your smart, AI-powered assistant designed to optimize online store
            performance. From personalized product recommendations to seamless
            order tracking and automated cart recovery,{' '}
            <a href='https://magbot.ai/'>
              <span className='text-[#FFF] underline'>MagBot</span>
            </a>{' '}
            takes your eCommerce experience to the next level.
          </p>

          {/* <div className='flex justify-center items-center w-full h-auto'>
                    <img src={Bannerimg} alt="Banner" className='w-auto max-h-[290px] md:max-h-[100%]' />
                </div> */}
        </div>

        {/* Right Section (Login Form) */}
        <div className='flex justify-center items-center p-10 md:min-h-screen md:overflow-y-auto'>
          <div className='w-full md:w-[90%] max-w-lg md:pl-[13%] md:pt-[10%] md:pr-[13%] h-auto lg:pt-[2%] lg:pl-[9%] lg:pr-[9%]'>
            <div className='flex justify-center items-center mb-3'>
              <img src={Logo} alt='Logo' className='w-auto h-[55px]' />
              {/* <h1 className='text-black font-bold text-[1.5rem] ml-2'>MagBot</h1> */}
            </div>

            <form className='w-full' onSubmit={onSubmit}>
              <div className='block mb-3 mt-4 text-center md:text-left'>
                <h2 className='font-[600] text-[20px] 2xl:text-[19px] 3xl:text-[18px] 4xl:text-[18px] leading-[24px] text-[#000]'>
                  Access Your{' '}
                  <span className='text-[#794ed8]'>
                    <a href='https://magbot.ai/'> MagBot</a>
                  </span>{' '}
                  Dashboard
                </h2>
                <p className='font-[400] text-[14px] mt-4'>
                  Log in to manage your store, track performance, and customize
                  your AI assistant for maximum impact.
                </p>
              </div>

              <div className='block mb-4'>
                <p className='font-[400] text-[14px] leading-[14px] mb-1 text-[#2D3748]'>
                  Email
                </p>
                <input
                  type='text'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className='w-full p-2 text-[12px] h-[38px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none mx-auto md:mx-0'
                />
              </div>

              <div className='block mb-1'>
                <p className='font-[400] text-[14px] leading-[14px] mb-1 text-[#2D3748]'>
                  Password
                </p>
                <div className='relative'>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className='w-full p-2 h-[38px] text-[12px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none mx-auto md:mx-0'
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className='absolute right-3 top-[50%] text-[12px] transform -translate-y-1/2 text-[#d4d4d4] cursor-pointer'
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
              </div>

              <div className='flex justify-end items-center'>
                <a
                  href='/forgot-password'
                  className='text-[14px] font-[400] leading-[19.6px] mt-3 text-[#007AFF] mb-2'
                >
                  Forgot Password?
                </a>
              </div>

              <button
                type='submit'
                className='bg-[#7C50DD] w-[100%] text-white h-[48px] rounded-[55px] font-semibold text-[14px]'
              >
                Log-in
              </button>
            </form>

            <div className='mt-2 text-center'>
              <span className='text-[14px] font-[400] text-[#2D3748]'>
                Don’t have an account yet?{' '}
                <Link to='/register' className='text-[#007AFF]'>
                  Register
                </Link>{' '}
                today and start transforming your eCommerce store.
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default LoginPage
