import Sidebar from './sidebar'
import MainComponent from './maincontent'
import '../../styles/chat.css'
import { GetChatLog } from '../../services/data'
import { useEffect, useState } from 'react'
import Loader from '../../components/loader'
import MobileViewChat from './mobileview'

const Chat = () => {
  const [isDataEmpty, setIsDataEmpty] = useState(null) // null means we're still loading
  const checkdata = new GetChatLog()

  useEffect(() => {
    const CheckCustomerId = async id => { 
      const res = await checkdata.Get_Customer_List(id)
      if (res.success) {
        setIsDataEmpty(res.data.length === 0) // set to true if empty, false otherwise
      } else {
        setIsDataEmpty(true) // In case of an error or no data
      }
    }

    const storeId = sessionStorage.getItem('store_id')
    if (storeId) {
      CheckCustomerId(storeId)
    } else {
      setIsDataEmpty(true) // If storeId is not available, no data
    }
  }, [])

  // While the data is being checked, display a loading state or empty state
  if (isDataEmpty === null) {
    return (
      <div className='min-h-[29.1rem] h-full flex justify-center items-center'>
        <Loader size={24} />
      </div>
    )
  }

  return (
    <div className='min-h-[29.1rem] h-full flex relative w-full'>
      {isDataEmpty ? (
        <div className='flex flex-1 items-center justify-center  text-gray-500'>
          No history available
        </div>
      ) : (
        <>
          <div className='block md:hidden lg:hidden'>
            <MobileViewChat />
          </div>
          <div className='hidden md:flex lg:flex w-full px-5 '>
            <Sidebar />
            <MainComponent />
          </div>
        </>
      )}
    </div>
  )
}

export default Chat
