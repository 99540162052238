import { useState } from 'react';
import { loginUser } from '../services/authentication/login-service';
import { toast } from 'react-toastify';
import { useAuth } from '../context/logincontext'; 

export const useLogin = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validateForm = () => {
        if (!email) {
            toast.error('Email is required',
                {
                    position: "bottom-center"
                }
            );
            return false;
        }
        if (!password) {
            toast.error('Password is required',{
                position: "bottom-center"
            });
            return false;
        }
        return true;
    };

    const handleLogin = async (navigate) => {
        if (!validateForm()) return;

        try {
            const result = await loginUser(email, password);
            
            login(result.data);
            
            toast.success('Login successful!',{
                position: "bottom-center"
            });
            navigate('/home');
        } catch (error) {
            toast.error(error.message,{
                position: "bottom-center"
            });
        }
    };

    return {
        passwordVisible,
        email,
        password,
        setEmail,
        setPassword,
        togglePasswordVisibility,
        handleLogin
    };
};
